import type { Base } from './base'

export interface MarketBin {
  ts: number
  date: string
  open: number
  high: number
  low: number
  close: number
  volume: number
}

export interface OHLCV {
  date: string
  open: number
  high: number
  low: number
  volume: number
  close: number
}

export type MarketInterval = '15m' | '30m' | '1h' | '1d'

export enum ExchangeId {
  binance = 'binance',
  binanceUS = 'binanceus',
  binanceCOINM = 'binancecoinm',
  binanceUSDM = 'binanceusdm',
  bitmex = 'bitmex',
  bybit = 'bybit',
  coinbasePro = 'coinbasepro',
  huobi = 'huobi'
}

'use client'
import { authService } from 'api/auth'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { toast } from 'sonner'
import { ButtonPrimary, EmailInput, HStack, PasswordInput } from 'ui/atoms'

const initialValues = {
  email: '',
  password: ''
}

export default function LoginPage() {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const searchParams = useSearchParams()
  const from = searchParams.get('from')

  const onSubmit = useCallback(
    (formValues: { email: string; password: string }) => {
      setLoading(true)
      authService
        .login(formValues.email ?? '', formValues.password ?? '')
        .then(() => {
          if (typeof from === 'string') {
            router.replace(from)
          } else {
            router.replace('/console/dashboard')
          }
        })
        .catch(() => {
          toast.error('Invalid email or password')
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [router, from]
  )

  const validate = useCallback((formValues: Record<string, any>) => {
    const formErrors: Record<string, any> = {}
    if (!formValues.email) {
      formErrors.email = 'Required'
    }
    if (!formValues.password) {
      formErrors.password = 'Required'
    }
    return formErrors
  }, [])

  return (
    <div className='flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 dark:bg-black sm:px-6 lg:px-8'>
      <div className='w-full max-w-md space-y-8'>
        <div>
          <img
            className='w-auto h-32 mx-auto rounded-lg center'
            src='/logo/logo.png'
            alt='Workflow'
          />
          <h2 className='mt-6 text-3xl font-semibold text-center text-gray-900 dark:text-gray-200'>
            Sign in to your account
          </h2>
        </div>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className='space-y-8'>
                <div className='space-y-2'>
                  <Field component={EmailInput} name='email' label='Email Address' required />
                  <Field component={PasswordInput} name='password' required />
                </div>

                <HStack>
                  <Link
                    href='/forgot-password'
                    className='flex text-sm font-medium text-green-600 hover:text-green-500'
                  >
                    Forgot your password?
                  </Link>
                  <ButtonPrimary type='submit' className='px-10' loading={loading}>
                    Sign In
                  </ButtonPrimary>
                </HStack>
              </form>
            )
          }}
        />
      </div>
    </div>
  )
}

import type { ExchangeId, OrderType, User } from '.'
import type { Base } from './base'
import type { OrderSide } from './order'
import type { StrategyId } from './strategy'

export type BacktestStatus = 'pending' | 'running' | 'complete' | 'failed'

export enum Side {
  LONG = 'long',
  SHORT = 'short'
}

export enum SignalType {
  sell = 'sell',
  buy = 'buy'
}

export interface BacktestSignal {
  date: string
  side: Side
  strategy: StrategyId
  signals: {
    $and: Array<Record<string, any>>
  }
  type: SignalType
}

export interface Backtest extends Base {
  parent: string | null
  portfolio: string | null | undefined
  user: User | string
  symbol: string
  branch: string
  startDate: string
  endDate: string
  tickRate: number | null
  breakevenAmount: number
  strategies: BacktestStrategy[]
  pnl: BacktestPnl[]
  orders: BacktestOrder[]
  positions: BacktestPosition[]
  exchange: ExchangeId
  stats: {
    total: BacktestStats
    long: BacktestStats
    short: BacktestStats
  }
  signals: BacktestSignal[]
  status: BacktestStatus
  contractPrice: number | null
  startingBalance: number
}

export interface BacktestPosition {
  id: string
  symbol: string
  strategy: string
  side: 'long' | 'short'
  openDate: string
  closeDate: string
  pnl: number
}

export interface BacktestStrategy {
  id: StrategyId
  json: any
}

export interface BacktestPnl {
  date: string
  strategy: string
  amount: number
  percent: number
  side: string
  entryPrice: number
  exitPrice: number
  reason: string
  groups: {
    label: string
    amount: number
  }[]
}

export interface BacktestStats {
  amount: number
  winningTrades: number
  losingTrades: number
  breakevenTrades: number
  winPercentage: number
  winLossRatio: number
  winAverage: number
  lossAverage: number
  winningStreak: number
  losingStreak: number
  maximumDrawdown: number
  sharpRatio: number
}

export interface BacktestOrder {
  openDate: string
  closeDate: string
  strategy: StrategyId
  amount: number
  price: number
  side: OrderSide
  type: OrderType
}

import type { Base } from './base'

export interface User extends Base {
  name: string
  email: string
  auth: UserAuth
}

export interface UserAuth {
  enabled: boolean
  role: UserRole
  passwordHash: string
}

export enum UserRole {
  admin = 'admin',
  client = 'client'
}

import type { Base } from './base'

export enum StrategyId {
  // Test
  testAlwaysLong = 'test/always-long',

  // Universal
  universalBigTrend = 'universal/big-trend',
  universalTrendFollowerDaily = 'universal/trend-follower-daily',
  universalTrendFollower = 'universal/trend-follower',
  universalBigTrendMACD = 'universal/big-trend-MACD',
  universalBigTrendMA = 'universal/big-trend-MA',
  universalOldBigTrend = 'universal/old-big-trend',
  universalManual1 = 'universal/manual1',
  universalManual2 = 'universal/manual2',
  universalPivots = 'universal/pivots',
  universalPivotsDaily = 'universal/pivots-daily',
  universalMeanReversionDaily = 'universal/mean-reversion-daily'
}

export interface Strategy extends Base {
  id: string
}

export type StrategyOption = { id: StrategyId | string; name: string } | null

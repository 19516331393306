import type { Base } from './base'

export interface Exchange extends Base {
  name: string
  identifier: string
  key: string
  secret: string
  user: string
  region: string
  options: Record<string, any>
}

export enum ExchangeRegion {
  usEast1 = 'us-east-1',
  apNortheast1 = 'ap-northeast-1',
  apSoutheast1 = 'ap-southeast-1',
  euWest1 = 'eu-west-1'
}

export interface ExchangeTime {
  lunaraTime: number
  exchangeTime: number
  latency: number
  drift: number
}

export interface ExchangePosition {
  id: string | number
  collateral: number
  contractSize: number
  contracts: number
  datetime?: string
  entryPrice: number
  hedged: boolean

  info: {
    entryPrice: string
    isAutoAddMargin: 'true' | 'false'
    isolatedMargin: string
    isolatedWallet: string
    leverage: string
    liquidationPrice: string
    marginType: string
    markPrice: string
    maxNotionalValue: string
    notional: string
    positionAmt: string
    positionSide: string
    symbol: string
    unRealizedProfit: string
    updateTime: number
  }
  initialMargin: number
  initialMarginPercentage: number
  leverage: number
  liquidationPrice?: number
  maintenanceMargin: number
  maintenanceMarginPercentage: number
  marginMode: string
  marginRatio?: number
  marginType: string
  notional: number
  markPrice?: number
  percentage?: number
  side?: 'short' | 'long'
  symbol: `${Uppercase<string>}/${Uppercase<string>}`
  timestamp: number
  unrealizedPnl: number
}

'use client'
import { Service } from 'api/common'
import { useAuthCredentials, useAuthStore, useAuthUser } from 'lib/auth'
import { useEffect, useState } from 'react'
import { type User, type UserCredentials, UserRole } from './types'

export type { User, UserCredentials }

export interface AuthResponse {
  user: User
  credentials: UserCredentials
}

export class AuthService extends Service<UserCredentials> {
  constructor() {
    super('/auth')
  }

  async login(email: string, password: string): Promise<AuthResponse> {
    const res = await this._login(email, password)

    this.user = res.user
    this.userCredentials = res.credentials
    useAuthStore.getState().init({ user: res.user, credentials: res.credentials })
    return res
  }

  async forgotPassword(email: string): Promise<void> {
    await this.post('/auth/forgot', { email })
  }

  async resetPassword(token: string, password: string): Promise<AuthResponse> {
    const res: AuthResponse = await this.post('/auth/reset', { token, password })
    this.user = res.user
    this.userCredentials = res.credentials
    useAuthStore.getState().init({ user: res.user, credentials: res.credentials })
    return res
  }

  async refreshCredentials(): Promise<AuthResponse> {
    const res: AuthResponse = await this.post('/auth/refresh', {})
    this.user = res.user
    this.userCredentials = res.credentials
    useAuthStore.getState().init({ user: res.user, credentials: res.credentials })
    return res
  }

  private async _login(email: string, password: string): Promise<AuthResponse> {
    return this.post('/auth/login', { email, password })
  }

  logout() {
    this.user = null
    this.userCredentials = null
    useAuthStore.getState().clear()
  }
}

export const authService = new AuthService()

export function useAuthRefresh() {
  const credentials = useAuthCredentials()
  useEffect(() => {
    if (!credentials) {
      return
    }
    const diff = new Date(credentials.expiresAt).getTime() - Date.now()
    if (diff < 7 * 24 * 60 * 60 * 1000) {
      authService.refreshCredentials()
    }
  }, [credentials])
}

export function useIsAdmin() {
  const [user, setUser] = useState<User | null>(null)
  const _user = useAuthUser()

  useEffect(() => {
    if (_user) {
      setUser(_user)
    }
  }, [_user])

  return user?.auth.role === UserRole.admin
}
